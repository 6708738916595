<template>
	<div>
		<h1>404</h1>

		Current route doesn't exist
	</div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({});
</script>
